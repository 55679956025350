import { handleError } from "../../../util/errors";
import apiBase from "../../api";
import { UPLOAD_CERTIFICATE } from "../../urls";

class CertificateService {
  uploadCertificate = async (certificate, pac, password) => {
    try {
      const formData = new FormData();
      formData.append("certificate", certificate);
      formData.append("pac", pac);
      formData.append("password", password);
      
      const response = await apiBase.post(UPLOAD_CERTIFICATE, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      
      return response;
    } catch (error) {
      handleError(error);
      throw error;
    }
  };
}

export default new CertificateService(); 