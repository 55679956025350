import React, { useState } from "react";
import styles from "./CertificatesScreen.module.scss";
import classNames from "classnames";
import { toast } from "react-toastify";
import DashboardLayout from "../../../components/layout/DashboardLayout";
import Input from "../../../components/input/Input";
import Button from "../../../components/button/Button";
import certificateService from "../../../apis/services/admin/certificateService";

const CertificatesScreen = () => {
  const [certificate, setCertificate] = useState(null);
  const [pac, setPac] = useState("");
  const [password, setPassword] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const handleFileChange = (e) => {
    setCertificate(e.target.files[0]);
    setUploadSuccess(false);
  };

  const handleSubmit = async (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    
    if (!certificate) {
      toast.error("Molimo izaberite sertifikat");
      return;
    }
    
    if (!pac) {
      toast.error("PAC je obavezan");
      return;
    }
    
    if (!password) {
      toast.error("Lozinka je obavezna");
      return;
    }
    
    setIsUploading(true);
    setUploadSuccess(false);
    
    try {
      await certificateService.uploadCertificate(certificate, pac, password);
      
      toast.success("Sertifikat uspešno otpremljen");
      setCertificate(null);
      setPac("");
      setPassword("");
      setUploadSuccess(true);
      
      // Reset the file input
      document.getElementById("certificate-input").value = "";
    } catch (error) {
      console.error("Error uploading certificate:", error);
      toast.error(
        error.response?.data?.message || 
        "Došlo je do greške prilikom otpremanja sertifikata"
      );
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <DashboardLayout>
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>Upravljanje sertifikatima</h1>
        </div>
        
        <div className={styles.content}>
          <div className={classNames(styles.section)}>
            <div className={styles.sectionHeader}>
              <p className={styles.sectionTitle}>Otpremanje sertifikata</p>
            </div>
            <div className={classNames(styles.card)}>
              <div className={styles.cardContent}>
                {uploadSuccess && (
                  <div className={styles.successMessage}>
                    Sertifikat je uspešno otpremljen!
                  </div>
                )}
                
                <div className={styles.form}>
                  <div className={styles.formGroup}>
                    <label className={styles.label}>Sertifikat (.p12 fajl)</label>
                    <div className={styles.fileInputWrapper}>
                      <input
                        id="certificate-input"
                        type="file"
                        accept=".p12"
                        onChange={handleFileChange}
                        className={styles.fileInput}
                        disabled={isUploading}
                      />
                    </div>
                    {certificate && (
                      <p className={styles.fileName}>
                        Izabrani fajl: {certificate.name}
                      </p>
                    )}
                  </div>
                  
                  <div className={styles.inputWrapper}>
                    <p>PAC</p>
                    <Input 
                      onChange={setPac} 
                      value={pac} 
                      disabled={isUploading}
                    />
                  </div>
                  
                  <div className={styles.inputWrapper}>
                    <p>Lozinka</p>
                    <Input 
                      onChange={setPassword} 
                      value={password} 
                      type="password"
                      disabled={isUploading}
                    />
                  </div>
                  
                  <div className={styles.buttonWrapper}>
                    <Button 
                      name={isUploading ? "Otpremanje..." : "Otpremi sertifikat"} 
                      onClick={handleSubmit} 
                      disabled={isUploading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default CertificatesScreen; 