import React, { useEffect, useState } from "react";
import styles from "./FiscalDeviceInfoScreen.module.scss";
import classNames from "classnames";
import { EditIcon, DeleteIcon } from "../../../../assets";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFiscalDevice,
  deleteFiscalDeviceResetState,
  fetchSigngleFiscalDevice,
  getFiscalDeviceInvoices,
  updateFiscalDeviceResetState,
} from "../../../../store/actions/admin/fiscalDevice";
import { useHistory } from "react-router-dom";
import moment from "moment";
import UpdateFiscalDeviceModal from "../../../../components/modals/updateFiscalDeviceModal/UpdateFiscalDeviceModal";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import AreYouSureModal from "../../../../components/modals/areYouSureModal/AreYouSureModal";
import ResourceList from "../../../../components/resourceList/ResourceList";
import { isMobile } from "react-device-detect";
import DoubleInvoicesModal from "../../../../components/modals/doubleInvoicesModal/DoubleInvoicesModal";

const HEADER_VALUES = [
  { label: "BROJ RAČUNA", key: "invoiceNumber", sortable: true },
  { label: "VREME NA PFR SERVERU", key: "sdcDateTime", sortable: true },
  { label: "UKUPAN IZNOS", key: "totalAmount", sortable: true },
  { label: "UKUPAN IZNOS POREZA", key: "taxTotalAmount", sortable: false },
  { label: "RAČUN" },
  // { label: "MAK ADRESA", key: "macAddress", sortable: true },
  // { label: "ONLINE", key: "lastOnlineAt", sortable: true },
];

const VENDING_CONTROLLERS_HEADER = [
  { label: "IME", key: "invoiceNumber", sortable: false },
  { label: "SERIJSKI BROJ", key: "serialNumber", sortable: false },
  { label: "VERZIJA", key: "version", sortable: false },
  { label: "MAK ADRESA", key: "invoiceNumber", sortable: false },
  { label: "ONLINE", key: "lastOnlineAt", sortable: false },
  {
    label: "LINK ZA PREUZIMANJE RAČUNA",
    key: "invoiceNumber",
    sortable: false,
  },
];

const FiscalDeviceInfoScreen = ({ id }) => {
  const dispatch = useDispatch();
  const [updateFiscalDeviceModalVisible, setUpdateFiscalDeviceModalVisible] =
    useState(false);
  const [deleteFiscalDeviceModalVisible, setDeleteFiscalDeviceModalVisible] =
    useState(false);

  const [doubleInvoicesModalOpen, setDoubleInvoicesModalOpen] = useState(false);
  const fiscalDevice = useSelector(
    (state) => state.fiscalDevices.singleFiscalDevice
  );
  const updateFiscalDeviceInfo = useSelector(
    (state) => state.fiscalDevices.updateFiscalDeviceInfo
  );
  const deleteFiscalDeviceInfo = useSelector(
    (state) => state.fiscalDevices.deleteFiscalDeviceInfo
  );
  const invoices = useSelector(
    (state) => state.fiscalDevices.fiscalDeviceInvoices
  );

  const [copiedItemText, setCopiedItemText] = useState(null);

  const history = useHistory();

  useEffect(() => {
    if (id) {
      getFiscalDeviceInvoices(id, 1, 15, "");
    }
  }, [id, dispatch]);

  const onLoadPage = (page) => {
    dispatch(
      getFiscalDeviceInvoices(id, page, invoices.perPage, searchTerm, "", "")
    );
  };
  const [searchTerm, setSearchTerm] = useState("");

  const onSort = (sortBy, order) => {
    dispatch(
      getFiscalDeviceInvoices(
        id,
        invoices.page,
        invoices.perPage,
        searchTerm,
        sortBy,
        order
      )
    );
  };

  useEffect(() => {
    if (copiedItemText) {
      setTimeout(() => {
        setCopiedItemText(null);
      }, 600);
    }
  }, [copiedItemText]);

  useEffect(() => {
    if (updateFiscalDeviceInfo.success) {
      toast.info("Sacuvano");
      setUpdateFiscalDeviceModalVisible(false);
      dispatch(updateFiscalDeviceResetState());
    }
  }, [updateFiscalDeviceInfo.success]);

  useEffect(() => {
    if (updateFiscalDeviceInfo.error) {
      toast.error("Greska");
    }
  }, [updateFiscalDeviceInfo.error]);

  useEffect(() => {
    if (deleteFiscalDeviceInfo.success) {
      toast.info("Obrisano");
      setUpdateFiscalDeviceModalVisible(false);
      dispatch(deleteFiscalDeviceResetState());
      history.goBack();
    }
  }, [deleteFiscalDeviceInfo.success]);

  useEffect(() => {
    if (deleteFiscalDeviceInfo.error) {
      toast.error("Greska");
    }
  }, [deleteFiscalDeviceInfo.error]);

  const getCommandType = (commandType) => {
    switch (commandType) {
      case "SYNC_MANAGED_VENDING_CONTROLLERS":
        return "Sinhorinizacija vending kontrolera";
      case "SYNC_POS_NUMBER":
        return "Sinhronizacija ESIR broja";
      case "SET_TRAINING_MODE":
        return "Postavi obuku";
      case "REMOVE_TRAINING_MODE":
        return "Ukini obuku";
      case "UPDATE_VENDING_PREFERENCES":
        return "Azuriraj postavke za vending";
      case "SYNC_MISSING_INVOICES":
        return "Sinhronizacija računa";
      case "SEND_PENDING_SDF_INVOICES":
        return "Pošalji račune na SDF (SDF računi na čekanju)";
      case "SEND_ALL_AUDIT_PACKAGES":
        return "Pošalji audit pakete (Pending audit paketi)";
      default:
        return null;
    }
  };

  const isoToLocal = (date) => {
    let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    let localISOTime = new Date(date.getTime() - tzoffset)
      .toISOString()
      .slice(0, -1);
    return localISOTime;
  };

  const renderCommands = () => {
    return (
      <>
        <div className={styles.vendingControllersHeader}>
          <div className={styles.headerItem}>
            <p>Vrsta</p>
          </div>
          <div className={styles.headerItem}>
            <p>Datum kreiranja</p>
          </div>
        </div>
        <div className={styles.cardContent}>
          {fiscalDevice?.data?.fiscalDeviceCommands?.map((command, index) => {
            return (
              <div
                className={classNames(
                  styles.itemRow,
                  styles.borderTop,
                  index ===
                  fiscalDevice?.data?.fiscalDeviceCommands.length - 1 &&
                  styles.borderBottom
                )}
              >
                <div className={styles.itemValue}>
                  <p>{getCommandType(command.type)}</p>
                </div>
                <div className={styles.itemValue}>
                  <p>{isoToLocal(new Date(command.createdAt))}</p>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const renderAdditionalInfo = () => {
    if (fiscalDevice?.data) {
      const lastOnline = fiscalDevice.data?.lastOnlineAt;
      const lastTimeOnline = lastOnline
        ? moment(lastOnline).tz(moment.tz.guess()).format("DD-MM-YYYY HH:mm:ss")
        : "";

      const isOnline =
        new Date().getTime() - fiscalDevice?.data.lastOnlineAt > 300 * 1000
          ? false
          : true;

      // Format certificate dates
      const certValidFrom = fiscalDevice.data?.cardCertificateValidFrom
        ? moment(fiscalDevice.data.cardCertificateValidFrom)
          .tz(moment.tz.guess())
          .format("DD-MM-YYYY")
        : "-";

      const certValidTo = fiscalDevice.data?.cardCertificateValidTo
        ? moment(fiscalDevice.data.cardCertificateValidTo)
          .tz(moment.tz.guess())
          .format("DD-MM-YYYY")
        : "-";

      const lastAttentionTime = fiscalDevice.data?.lastAttentionTime
        ? moment(fiscalDevice.data.lastAttentionTime)
          .tz(moment.tz.guess())
          .format("DD-MM-YYYY HH:mm:ss")
        : "-";

      const lastInvoiceAttempt = fiscalDevice.data?.lastInvoiceAttempt
        ? moment(fiscalDevice.data.lastInvoiceAttempt)
          .tz(moment.tz.guess())
          .format("DD-MM-YYYY HH:mm:ss")
        : "-";

      const address = fiscalDevice?.data?.businessUnit?.address;
      const formattedAddress = address 
        ? `${address.name || ''}, ${address.locality || ''}, ${address.district || ''}, ${address.countryCode || ''}`
        : "-";
      return (
        <>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Ime:</p>
            </div>
            <div className={classNames(styles.configValue)}>
              <p>{fiscalDevice?.data?.name}</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Mac adresa:</p>
            </div>
            <div className={styles.configValue}>
              <p>{fiscalDevice?.data?.macAddress}</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Firma:</p>
            </div>
            <div className={styles.configValue}>
              <p>{fiscalDevice?.data?.businessUnit?.company?.name}</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>PIB:</p>
            </div>
            <div className={styles.configValue}>
              <p>{fiscalDevice?.data?.businessUnit?.company?.PIB}</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Poslovni prostor:</p>
            </div>
            <div className={styles.configValue}>
              <p>{formattedAddress}</p>
            </div>
          </div>
          {fiscalDevice?.data?.scUuid && (
            <div className={styles.configRow}>
              <div className={styles.configLabel}>
                <p className={styles.textBold}>Bezbednosni element:</p>
              </div>
              <div className={styles.configValue}>
                <p>{fiscalDevice?.data?.scUuid}</p>
              </div>
            </div>
          )}
          {fiscalDevice?.data?.vsdcKeystorePrincipal && (
            <div className={styles.configRow}>
              <div className={styles.configLabel}>
                <p className={styles.textBold}>Keystore CN:</p>
              </div>
              <div className={styles.configValue}>
                <p>{fiscalDevice?.data?.vsdcKeystorePrincipal}</p>
              </div>
            </div>
          )}
          {fiscalDevice?.data?.cardCertificateValidFrom && (
            <div className={styles.configRow}>
              <div className={styles.configLabel}>
                <p className={styles.textBold}>Sertifikat važi od:</p>
              </div>
              <div className={styles.configValue}>
                <p>{certValidFrom}</p>
              </div>
            </div>
          )}
          {fiscalDevice?.data?.cardCertificateValidTo && (
            <div className={styles.configRow}>
              <div className={styles.configLabel}>
                <p className={styles.textBold}>Sertifikat važi do:</p>
              </div>
              <div className={styles.configValue}>
                <p>{certValidTo}</p>
              </div>
            </div>
          )}
          {fiscalDevice.data?.lastAttentionTime && (
            <div className={styles.configRow}>
              <div className={styles.configLabel}>
                <p className={styles.textBold}>Last attention:</p>
              </div>
              <div className={styles.configValue}>
                <p>{lastAttentionTime}</p>
              </div>
            </div>
          )}
          {fiscalDevice?.data?.lastInvoiceAttempt && (
            <div className={styles.configRow}>
              <div className={styles.configLabel}>
                <p className={styles.textBold}>Last invoice attempt:</p>
              </div>
              <div className={styles.configValue}>
                <p>{lastInvoiceAttempt}</p>
              </div>
            </div>
          )}
          {fiscalDevice?.data?.version && (
            <div className={styles.configRow}>
              <div className={styles.configLabel}>
                <p className={styles.textBold}>Verzija:</p>
              </div>
              <div className={styles.configValue}>
                <p>{fiscalDevice?.data?.version}</p>
              </div>
            </div>
          )}
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Zadnji put viđen</p>
            </div>
            <div className={styles.configValue}>
              <div
                className={classNames(isOnline ? styles.online : styles.ofline)}
              />
              <p>{lastTimeOnline}</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Javna adresa:</p>
            </div>
            <div className={styles.configValue}>
              <p>{fiscalDevice?.data?.publicIpAddress}</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Status čitača:</p>
            </div>
            <div className={styles.configValue}>
              <div
                className={classNames(
                  fiscalDevice?.data?.readerConnected
                    ? styles.online
                    : styles.ofline
                )}
              />
              <p>
                {fiscalDevice?.data?.readerConnected
                  ? "Čitač povezan"
                  : "Čitač nije povezan"}
              </p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Status kartice:</p>
            </div>
            <div className={styles.configValue}>
              <div
                className={classNames(
                  fiscalDevice?.data?.cardConnected
                    ? styles.online
                    : styles.ofline
                )}
              />
              <p>
                {fiscalDevice?.data?.cardConnected
                  ? "Kartica povezana"
                  : "Kartica nije povezana"}
              </p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Status PIN-a:</p>
            </div>
            <div className={styles.configValue}>
              <div
                className={classNames(
                  fiscalDevice?.data?.pinOk
                    ? styles.online
                    : styles.ofline
                )}
              />
              <p>
                {fiscalDevice?.data?.pinOk
                  ? "PIN verifikovan"
                  : "PIN nije verifikovan"}
              </p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Interni SDC URL:</p>
            </div>
            <div className={styles.configValue}>
              <p>{fiscalDevice?.data?.deviceEsdcUrl}</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Interni pin:</p>
            </div>
            <div className={styles.configValue}>
              <p>{fiscalDevice?.data?.internalPin}</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Interni pac:</p>
            </div>
            <div className={styles.configValue}>
              <p>{fiscalDevice?.data?.internalPac}</p>
            </div>
          </div>
          {fiscalDevice?.data?.deviceUseAsVending !== undefined && (
            <div className={styles.configRow}>
              <div className={styles.configLabel}>
                <p className={styles.textBold}>Vending režim:</p>
              </div>
              <div className={styles.configValue}>
                <div
                  className={classNames(
                    fiscalDevice?.data?.deviceUseAsVending
                      ? styles.online
                      : styles.ofline
                  )}
                />
                <p>
                  {fiscalDevice?.data?.deviceUseAsVending
                    ? "Uključen"
                    : "Isključen"}
                </p>
              </div>
            </div>
          )}
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Status LPFR:</p>
            </div>
            <div className={styles.configValue}>
              <div
                className={classNames(
                  fiscalDevice?.data?.isInitialized
                    ? styles.online
                    : styles.ofline
                )}
              />
              <p>
                {fiscalDevice?.data?.isInitialized
                  ? "LPFR je konfigurisan"
                  : "LPFR nije konfigurisan"}
              </p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Iščitavanje započeto:</p>
            </div>
            <div className={styles.configValue}>
              <p>
                {fiscalDevice?.data?.remoteAuditStartTimestamp
                  ? moment(fiscalDevice?.data?.remoteAuditStartTimestamp)
                    .tz("Europe/Belgrade")
                    .format("DD-MM-YYYY HH:mm:ss")
                  : ""}
              </p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Iščitavanje završeno:</p>
            </div>
            <div className={styles.configValue}>
              <p>
                {fiscalDevice?.data?.lastRemoteAuditEndTime
                  ? moment(fiscalDevice?.data?.lastRemoteAuditEndTime)
                    .tz("Europe/Belgrade")
                    .format("DD-MM-YYYY HH:mm:ss")
                  : ""}
              </p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Broj audit paketa:</p>
            </div>
            <div className={styles.configValue}>
              <p>{fiscalDevice?.data?.auditFilesCount}</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>SDF računi na čekanju:</p>
            </div>
            <div className={styles.configValue}>
              <p>{fiscalDevice?.data?.pendingFSCInvoices?.length}</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Računi na čekanju:</p>
            </div>
            <div className={styles.configValue}>
              <p>{fiscalDevice?.data?.pendingInvoices?.length}</p>
            </div>
          </div>
          <div className={styles.configRow}>
            <div className={styles.configLabel}>
              <p className={styles.textBold}>Nepostojeci računi:</p>
            </div>
            <div className={styles.configValue}>
              <p>
                {fiscalDevice?.data?.missingAuditPackages &&
                  fiscalDevice?.data?.missingAuditPackages.length}
              </p>
            </div>
          </div>
          {fiscalDevice?.data?.localIpAddress && (
            <div className={styles.configRow}>
              <a
                href={`http://${fiscalDevice?.data?.localIpAddress}:5000/connect?ip=${fiscalDevice?.data?.localIpAddress}`}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.aTag}
              >
                <p
                  className={classNames(styles.configLabel, styles.clickable)}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  Lokalna IP adresa: {fiscalDevice?.data?.localIpAddress}
                </p>
              </a>
            </div>
          )}
        </>
      );
    }
  };

  const renderResourceListRow = (invoice, index) => {
    let totalTaxAmount = 0;
    invoice?.taxItems?.forEach((taxItem) => {
      totalTaxAmount += taxItem.amount;
    });

    const localDt = invoice.sdcDateTimeTimestamp
      ? moment(invoice.sdcDateTimeTimestamp)
        .tz(moment.tz.guess())
        .format("HH:mm:ss DD-MM-YYYY")
      : moment
        .utc(invoice.createdAt)
        .tz(moment.tz.guess())
        .format("HH:mm:ss DD-MM-YYYY");

    if (isMobile) {
      return (
        <div className={styles.listCard}>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Broj računa</p>
            <p className={styles.rowCardValue}>{invoice.invoiceNumber}</p>
          </div>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Vreme na PFR serveru</p>
            <p className={styles.rowCardValue}>{localDt}</p>
          </div>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Ukupan iznos</p>
            <p className={styles.rowCardValue}>{invoice.totalAmount}</p>
          </div>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Porez</p>
            <p className={styles.rowCardValue}>{totalTaxAmount}</p>
          </div>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Zurnal</p>
            <a
              href={invoice.convertedUrlPreview}
              target="_blank"
              className={styles.journalLink}
            >
              Prikaži zurnal
            </a>
          </div>
        </div>
      );
    }
    return (
      <div
        className={classNames(
          styles.row,
          index % 2 === 0 ? styles.even : styles.odd
        )}
        key={invoice.id}
        onClick={() => { }}
      >
        <div className={styles.cell}>
          <p>{invoice.invoiceNumber}</p>
        </div>
        <div className={styles.cell}>
          <p>{localDt}</p>
        </div>
        <div className={styles.cell}>
          <p>{invoice.totalAmount}</p>
        </div>
        <div className={styles.cell}>
          <p>{totalTaxAmount}</p>
        </div>
        <div className={styles.cell}>
          <a
            href={invoice.convertedUrlPreview}
            target="_blank"
            className={styles.journalLink}
          >
            Prikaži zurnal
          </a>
        </div>
      </div>
    );
  };

  const renderVedndingControllerListRow = (item, index) => {
    const isOnline =
      new Date().getTime() - parseInt(item.lastOnlineAt) < 5 * 60 * 1000;
    const lastTimeOnline = item.lastOnlineAt
      ? moment(item.lastOnlineAt)
        .tz(moment.tz.guess())
        .format("DD-MM-YYYY HH:mm:ss")
      : "";

    if (isMobile) {
      return (
        <div className={styles.listCard}>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Ime</p>
            <p className={styles.rowCardValue}>{item.name}</p>
          </div>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Mak adresa</p>
            <Link
              to={"/vending-controllers/" + item.id}
              className={styles.link}
            >
              <p className={styles.rowCardValue}>{item.macAddress}</p>
            </Link>
          </div>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Online</p>
            <div className={styles.flexRow}>
              {isOnline ? (
                <div className={styles.online} />
              ) : (
                <div className={styles.ofline} />
              )}
              <p className={styles.rowCardValue}>{lastTimeOnline}</p>
            </div>
          </div>
          <div className={styles.rowCard}>
            <p className={styles.rowCardTitle}>Link za preuzimanje računa</p>
            <p
              className={classNames(styles.rowCardValue, styles.clickable)}
              onClick={() => {
                if (copiedItemText !== item.id) {
                  setCopiedItemText(item.id);
                  navigator.clipboard.writeText(
                    `https://sdf.rs/invoices/validation/${item.macAddress}`
                  );
                }
              }}
            >
              {copiedItemText === item.id ? "Kopirano" : `Kopiraj tekst`}
            </p>
          </div>
        </div>
      );
    } else
      return (
        <div
          className={classNames(
            styles.row,
            index % 2 === 0 ? styles.even : styles.odd
          )}
          key={item.id}
          onClick={() => { }}
        >
          <div className={styles.cell}>
            <p>{item.name}</p>
          </div>
          <div className={styles.cell}>
            <p>{item.serialNumber}</p>
          </div>
          <div className={styles.cell}>
            <p>{item.version}</p>
          </div>
          <div className={styles.cell}>
            <Link
              to={"/vending-controllers/" + item.id}
              className={styles.link}
            >
              <p className={styles.clickable}>{item.macAddress}</p>
            </Link>
          </div>
          <div className={styles.cell}>
            <div className={styles.flexRow}>
              {isOnline ? (
                <div className={styles.online} />
              ) : (
                <div className={styles.ofline} />
              )}
              <p className={styles.rowCardValue}>{lastTimeOnline}</p>
            </div>
          </div>
          <div className={styles.cell}>
            <p
              className={styles.clickable}
              onClick={() => {
                if (copiedItemText !== item.id) {
                  setCopiedItemText(item.id);
                  navigator.clipboard.writeText(
                    `https://sdf.rs/invoices/validation/${item.macAddress}`
                  );
                }
              }}
            >
              {copiedItemText === item.id ? "Kopirano" : "Kopiraj link"}
            </p>
          </div>
        </div>
      );
  };

  return (
    <>
      <UpdateFiscalDeviceModal
        fiscalDevice={fiscalDevice?.data}
        modalOpen={updateFiscalDeviceModalVisible}
        onClose={() => setUpdateFiscalDeviceModalVisible(false)}
        attempt={updateFiscalDeviceInfo.attempt}
      />
      <AreYouSureModal
        onConfirm={() => {
          dispatch(deleteFiscalDevice(fiscalDevice?.data?.id));
        }}
        modalOpen={deleteFiscalDeviceModalVisible}
        closeModal={() => setDeleteFiscalDeviceModalVisible(false)}
      />
      <DoubleInvoicesModal
        modalOpen={doubleInvoicesModalOpen}
        onClose={() => {
          setDoubleInvoicesModalOpen(false);
        }}
        fiscalDeviceId={id}
      />
      <div className={styles.content}>
        <div className={classNames(styles.gridHalves)}>
          <div className={classNames(styles.sectionMax, styles.maxHeight)}>
            <div className={styles.sectionHeader}>
              <p className={styles.sectionTitle}>Informacije o uređaju</p>
              <div className={styles.actionsWrap}>
                <img
                  src={DeleteIcon}
                  alt="delete"
                  className={styles.deleteButton}
                  onClick={() => {
                    setDeleteFiscalDeviceModalVisible(true);
                  }}
                />
                <img
                  src={EditIcon}
                  alt="edit"
                  className={styles.editButton}
                  onClick={() => {
                    setUpdateFiscalDeviceModalVisible(true);
                  }}
                />
              </div>
            </div>
            <div className={classNames(styles.card)}>
              <div className={styles.cardContent}>{renderAdditionalInfo()}</div>
            </div>
          </div>
          <div className={classNames(styles.sectionMax, styles.maxHeight)}>
            <div className={styles.sectionHeader}>
              <p className={styles.sectionTitle}>Komande na čekanju</p>
            </div>
            <div className={classNames(styles.card)}>
              <div className={styles.cardContent}>{renderCommands()}</div>
            </div>
          </div>
        </div>
        {fiscalDevice?.data?.vendingControllers &&
          fiscalDevice?.data?.vendingControllers.length !== 0 && (
            <div className={classNames(styles.section, styles.marginTop)}>
              <div className={styles.sectionHeader}>
                <p className={styles.sectionTitle}>Vending kontroleri</p>
              </div>
              {fiscalDevice?.data?.vendingControllers && (
                <ResourceList
                  resource={{ data: fiscalDevice?.data?.vendingControllers }}
                  onSort={() => { }}
                  onLoadPage={() => { }}
                  headerValues={VENDING_CONTROLLERS_HEADER}
                  rowRender={renderVedndingControllerListRow}
                />
              )}
            </div>
          )}
        {invoices && invoices.length !== 0 && (
          <div className={classNames(styles.section, styles.marginBottom)}>
            <div className={styles.sectionHeader}>
              <p className={styles.sectionTitle}>Računi</p>
            </div>
            <div>
              <ResourceList
                rowRender={renderResourceListRow}
                onSort={onSort}
                onLoadPage={onLoadPage}
                headerValues={HEADER_VALUES}
                resource={invoices}
                minHeight
                showLoader={true}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FiscalDeviceInfoScreen;
