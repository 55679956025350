import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { LogoBlue } from "../../../assets";
import styles from "./SideBar.module.scss";

const SideBar = ({ open }) => {
  const user = useSelector((state) => state.auth.user);
  const history = useHistory();
  const location = useLocation();

  const items =
    user?.role === "ADMIN"
      ? [
        {
          name: "Firme",
          path: "/taxpayers",
          selected: location.pathname === "/taxpayers",
        },
        {
          name: "Fiskalni uređaji",
          path: "/fiscal-devices",
          selected: location.pathname.includes("/fiscal-devices"),
        },
        {
          name: "Vending kontroleri",
          path: "/vending-controllers/pending",
          selected: location.pathname.includes("/vending-controllers"),
        },
        {
          name: "Poreske stope",
          path: "/tax-rates",
          selected: location.pathname.includes("/tax-rates"),
        },
        {
          name: "Korisnici",
          path: "/users",
          selected: location.pathname.includes("/users"),
        },
        {
          name: "Analitika",
          path: "/analytics",
          selected: location.pathname.includes("/analytics"),
        },
        {
          name: "Sertifikati",
          path: "/certificates",
          selected: location.pathname.includes("/certificates"),
        },
        {
          name: "Audit Logovi",
          path: "/audit-logs",
          selected: location.pathname.includes("/audit-logs"),
        },
      ]
      : [
        {
          name: "Početna",
          path: "/dashboard",
          selected: location.pathname === "/dashboard",
        },
        {
          name: "Fiskalni uređaji",
          path: "/fiscal-devices",
          selected: location.pathname.includes("/fiscal-devices"),
        },
        // {
        //   name: "Poslovni prostori",
        //   path: "/bussiness-units",
        //   selected: location.pathname === "/bussiness-units",
        // },
      ];
  return (
    <div
      className={classNames(open ? styles.sideBar : styles.sideBarClosed)}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className={styles.logoWrap}>
        <img src={LogoBlue} alt="logo" className={styles.logo} />
        <p className={styles.lpfr}>LPFR</p>
      </div>
      <div className={styles.items}>
        {items.map((item) => (
          <div
            className={classNames(
              styles.disableTextSelect,
              item.selected ? styles.itemSelected : styles.item
            )}
            onClick={() => {
              history.push(item.path);
            }}
          >
            <div
              className={
                item.selected ? styles.borderLeft : styles.borderLeftTransparent
              }
            ></div>
            <p
              className={
                item.selected ? styles.itemNameSelected : styles.itemName
              }
            >
              {item.name}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SideBar;
